import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`
const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is The Difference Between A Bookkeeper And An Accountant? Do I Need Both?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Bookkeepers process the paperwork for a business’s financial transactions to ensure accuracy and efficiency. They are knowledgeable and able to offer advice regarding credits and debits, accounts payable procedures, the chart of accounts, sales and accounts receivable, payroll, etc. Ideally, your business needs both a bookkeeper and an accountant because they offer different areas of expertise to keep the financial aspect of your business running smoothly."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why should I outsource? Can’t I do it myself?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can keep or maintain your business’s records, however, accounts can consume a lot of time and because it is complex, you can encounter a tax or VAT problem. The best practice is to outsource to Codestaff and get busy with growing or running your business."
        }
    }
}

const Bookkeeper = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Bookkeepers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Bookkeepers. Top
                                    companies and start-ups choose Codestaff professional Bookkeepers
                                    for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Bookkeeper now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE BOOKKEEPERS' banner='/bookkeeper.png' bannerAlt='bookkeeper banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default Bookkeeper